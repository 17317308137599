<template>
  <div class="app flex-row align-items-center">
    <div class="container">
      <b-row class="justify-content-center">
        <b-col md="6">
          <div class="clearfix">
            <h1 class="float-left display-3 mr-4">404</h1>
            <h4 class="pt-3">Oops! You're lost.</h4>
            <p class="text-muted clearfix">The page you are looking for was not found.</p>
            <b-button v-if="!auth"  :to="{ name: 'Login' }" variant="outline-dark" class="mr-3">Login</b-button>
            <b-button v-if="auth"  :to="{ name: 'Dashboard' }" variant="outline-dark" class="">Return to Dashboard</b-button>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Page404',
  computed: {
    auth() {
      return this.$store.getters.isAuthenticated
    },
  }
}
</script>
